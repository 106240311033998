import Axios from "@/services/Axios";

export default class ServerService extends Axios{

  async getServer(id) {
      return  await this.axios.get(`/server/${id}`);
 }
    async getDiskUsage() {
        return  await this.axios.get(`/disk-usage`);
    }

    async getMemUsage() {
        return  await this.axios.get(`/mem-usage`);
    }

    async getLogSize() {
        return  await this.axios.get(`/log-size`);
    }

}
