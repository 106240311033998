import Axios from "./Axios";

export default class UpdateService extends Axios {
  async getUpdates() {
    return await this.axios.get("/updates");
  }

  async lastUpdate() {
    return await this.axios.get("/last-update");
  }

  async currentVersion() {
    return await this.axios.get("/current-version");
  }

  async update(version) {
    return await this.axios.post("/update",version);
  }
}
