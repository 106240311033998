<template>
  <v-card flat>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4 lg4 xl4>
          <v-card style="background-color:#6c5ce7;height: 130px">
            <v-card-title class="justify-center" style="color: white"><h3>Uso do disco</h3></v-card-title>
            <v-card-text>
                <v-layout row wrap justify-space-between>
                  <v-flex xs2>
                    <v-icon
                        x-large
                        color="white"
                    >
                      cloud
                    </v-icon>
                  </v-flex>
                  <v-flex xs10 pt-3 >
                    <h4 class="text-end" style="color: white;font-size: 30px">{{ diskUsage }}</h4>
                  </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-flex >
        <v-flex xs12 sm6 md4 lg4 xl4>
          <v-card style="background-color:#a29bfe;height: 130px">
            <v-card-title class="justify-center" style="color: white"><h3>Uso da memória RAM</h3></v-card-title>
            <v-card-text>
                <v-layout row wrap justify-space-between>
                  <v-flex xs2>
                    <v-icon
                        x-large
                        color="white"
                    >
                      memory
                    </v-icon>
                  </v-flex>
                  <v-flex xs10 pt-3 >
                    <h4 class="text-end" style="color: white;font-size: 30px">{{ memUsage }}</h4>
                  </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-flex >
        <v-flex xs12 sm12 md4 lg4 xl4 >
          <v-card style="background-color:#00cec9;height: 130px">
            <v-card-title class="justify-center" style="color: white"><h3>Tamanho dos logs</h3></v-card-title>
            <v-card-text>
                <v-layout row wrap justify-space-between>
                  <v-flex xs2>
                    <v-icon
                        x-large
                        color="white"
                    >
                      folder_open
                    </v-icon>
                  </v-flex>
                  <v-flex xs10 pt-3>
                    <h2 class="text-end" style="color: white;font-size: 30px">{{ logSize }}</h2>
                  </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-flex >

        <v-flex xs12  >
          <v-card style="background-color:#00b894">
            <v-card-title class="justify-center" style="color: white"><h3>Atualização</h3></v-card-title>
            <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12>
                    <h3 style="color:#2d3436">Versão: {{ currentVersion }}</h3>
                  </v-flex>
                  <v-flex xs12>
                    <h3 style="color: white">Ultima versão disponível: {{ lastVersion.version }}</h3>
                  </v-flex>
                  <v-flex xs12>
                    <h3 style="color: white">Data/Hora da versão: {{ lastVersion.date }}</h3>
                  </v-flex>
                  <v-flex xs12>
                    <h3 style="color: white">Notas da versão: {{ lastVersion.text }}</h3>
                  </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn block :loading="loading" @click="confirmUpdate">
                Atualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <!--        <v-flex xs12 sm6 md6 lg6 x6 >-->
        <!--          <v-card style="background-color:#00b894">-->
        <!--            <v-card-title class="justify-center" style="color: white"><h3>Atualização PDV</h3></v-card-title>-->
        <!--            <v-card-text>-->
        <!--              <v-container>-->
        <!--                <v-layout row wrap>-->
        <!--                  <v-flex xs12>-->
        <!--                    <h3 style="color:#2d3436">Versão: {{ currentVersion }}</h3>-->
        <!--                  </v-flex>-->
        <!--                  <v-flex xs12>-->
        <!--                    <h3 style="color: white">Ultima versão disponível: {{ lastVersion.version }}</h3>-->
        <!--                  </v-flex>-->
        <!--                  <v-flex xs12>-->
        <!--                    <h3 style="color: white">Data/Hora da versão: {{ lastVersion.date }}</h3>-->
        <!--                  </v-flex>-->
        <!--                  <v-flex xs12>-->
        <!--                    <h3 style="color: white">Notas da versão: {{ lastVersion.text }}</h3>-->
        <!--                  </v-flex>-->
        <!--                </v-layout>-->
        <!--              </v-container>-->
        <!--            </v-card-text>-->
        <!--            <v-card-actions>-->
        <!--              <v-btn block :loading="loading" @click="confirmUpdate">-->
        <!--                Atualizar-->
        <!--              </v-btn>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-flex>-->
      </v-layout>
    </v-card-text>
  </v-card>


</template>

<script>
import UpdateService from "@/services/UpdateService";
import ServerService from "@/services/ServerService";

export default {
  name: 'Dashboard',
  data: function () {
    return {
      lastVersion: "0",
      logSize: "0 Kb",
      diskUsage: "0 / 0 N",
      memUsage: "0 / 0 N",
      currentVersion: "0",
      loading: false
    };
  },
  watch: {},
  async mounted() {
    await this.getLastVersion();
    await this.getCurrentVersion();
    await this.getDiskUsage();
    await this.getMemUsage();
    await this.getLogSize();
  },
  methods: {
    async confirmUpdate() {
      let res;
      await this.$confirm(
          "Deseja atualizar o servidor da versao "+this.currentVersion+" para a versão "+this.lastVersion.version+" ?",
          async () => {
            console.log("Atualizando ....")
            try {
              this.loading = true
              res = await new UpdateService().update(this.lastVersion)
            } finally {
              this.loading = false
            }
          }
      )
      if (res) this.$info.open(res.data)
    },
    async getLastVersion() {
      const res = await new UpdateService().lastUpdate()
      console.log(res)
      this.lastVersion = res.data
    },
    async getCurrentVersion() {
      const res = await new UpdateService().currentVersion()
      if (res.data) {
        localStorage.setItem("currentVersion", res.data)
        this.currentVersion = res.data
      }
    },async getDiskUsage() {
      const res = await new ServerService().getDiskUsage()
      if (res.data) {
        this.diskUsage = res.data.data
      }
    },async getMemUsage() {
      const res = await new ServerService().getMemUsage()
      if (res.data) {
        this.memUsage = res.data.data
      }
    },async getLogSize() {
      const res = await new ServerService().getLogSize()
      if (res.data) {
        this.logSize = res.data.data
      }
    }
  },
  computed: {}
};
</script>